import React from 'react';


// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function FooterFirst() {

    return (
        <div className="footer-new-index footer-new">
            <div className="footer-content-new">
                <div className="footer-content-row footer-content-row-1">
                    <a href="#">8 (499) 372-0322</a>
                    <div>
                        <a href="https://b2cpl.ru/">Задать вопрос</a>
                        <a href="https://b2cpl.ru/">Стать клиентом</a>
                    </div>
                </div>
                <div className="footer-content-row footer-content-row-2">
                    <p className="pc">Для получателей посылок ежедневно с 9:00 до 21:00 по московскому времени</p>
                    <p className="footer-right">B2CPL- курьерская служба для интернет-магазинов</p>
                </div>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------


