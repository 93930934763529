import axios from "axios";

const axiosDefaultOptions =
{
    baseURL: "https://apiz.b2cpl.ru/api/",
    headers: {'Content-Type': 'application/json'},
};

let axiosInstance = axios.create(axiosDefaultOptions);

axiosInstance.interceptors.request.use(function (config)
{
    if (!config.headers.Authorization)
    {
        const token = localStorage.getItem('token');
        config.headers.Authorization = token ? `Bearer ${token}` : 'Bearer token';
    }

    return config;
});

export default axiosInstance;

