import React from 'react';
import Button from '@material-ui/core/Button';
import axiosDut from "../js/api_axios";
import { sysDate, sysDeliveryTime, sysTime, sysLog } from '../js/dut_sys';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderChangeButton(props)
{
    if (props.flag_change)
    {
        return (
            <div className="change-button">
                <Button color="primary" onClick={props.handleChangeButtonClick}>
                    Изменить
                </Button>
            </div>
        );
    }

    return null;
}

export function RenderDateTimeBlock(props)
{
    let flag_arrive = false;
    let flag_delivery = false;
    let title = "";

    if (props.data.delivery_date && props.data.delivery_date !== "0001-01-01T00:00:00" && props.data.delivery_time_begin && props.data.delivery_time_end) {
        flag_delivery = true;
        title = "Выбраная вами дата и время доставки";
    }

    if (props.data.date_estimate_arrive && props.data.date_estimate_arrive !== "0001-01-01T00:00:00") {
        flag_arrive = true;
        title = "Дата прибытия в ваш город";
    }

    // ----------------------------------------------------------------

    const handleChangeButtonClick = () =>
    {
        if (props.flag_delivery) {
            props.selectDate([]);
            sysLog("BodyUniq.DateChange.ChangedMind", {
                uniq_code: props.data.uniq_code
            })
            return;
        }
        axiosDut.get(`packages/changeallow/${props.data.code}`)
            .then(res => {
                if (res.data.flag_error) {
                    sysLog(`BodyUniq.DateChange.${props.data.uniq_code}`, {
                        status_code: res.data.status_code,
                        code: props.data.code,
                        uniq_code: props.data.uniq_code
                    });
                    props.showAlert(res.data.message || "Что-то не получилось :с", "error");
                } else {
                    const payload = res.data.payload;
                    if (payload.flag_allow) {
                        props.selectDate(payload.dates_offer);
                        sysLog("BodyUniq.DateChange", {
                            uniq_code: props.data.uniq_code
                        });
                    } else {
                        props.showAlert(payload.message || "Сейчас нельзя поменять дату", "error");
                        sysLog("BodyUniq.DateChange.0", {
                            message: payload.message,
                            code: props.data.code,
                            uniq_code: props.data.uniq_code
                        });
                    }
                }
            });
    };

    // ----------------------------------------------------------------

    if (props.data.flag_delivery || (!flag_arrive && !flag_delivery)) {
        return null;
    }

    // ----------------------------------------------------------------
    // Есть дата доставки
    if (flag_delivery) {
        return (
            <div className="delivery-date-block">

                <div className="title">
                    {title}
                </div>
                <div className="delivery-data">

                    <div className="block date">
                        <img src="/img/calendar-3.svg" alt="" />
                        <div className="value">
                            {sysDate(props.data.delivery_date)}
                        </div>
                    </div>

                    <div className="block time">
                        <img src="/img/time-3.svg" alt="" />
                        <div className="value">
                            {sysDeliveryTime(props.data.delivery_time_begin, props.data.delivery_time_end)}
                        </div>
                    </div>
                </div>

                <RenderChangeButton handleChangeButtonClick={handleChangeButtonClick} flag_change={props.flag_change} />
            </div>
        );
    }

    // ----------------------------------------------------------------
    // Есть дата прибытия
    if (flag_arrive) {
        return (
            <div className="delivery-date-block arrive">
                <div className="title">
                    {title}
                </div>
                <div className="delivery-data">

                    <div className="block date">
                        <img src="/img/calendar-3.svg" alt="" />
                        <div className="value">
                            {sysDate(props.data.date_estimate_arrive)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderPackageParts(props) {
    return (
        props.parts.map((item, idx) =>
            <React.Fragment key={idx}>
                <div className="cell first">
                    <span className="order-span-title">
                        {item.part_name + (item.part_code ? (" (" + item.part_code + ")") : "")}
                    </span>
                </div>
                <div className="cell last">
                    <span className="order-span-quality">
                        {item.part_qty} шт
                    </span>
                </div>
            </React.Fragment>
        ));
}

function RenderPartsButton(props) {
    if (props.flag_parts) {
        return (
            <Button className="history-button" onClick={props.handlePackageParts}>
                Состав заказа
            </Button>
        );
    }

    return null;
}

export function RenderStatusBlock(props) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [packageParts, setPackageParts] = React.useState([]);

    if (!props.history) {
        return null;
    }

    // ----------------------------------------------------

    const historyItems = props.history.map
        (
            (item, idx) =>
                <div className="history-content-row" key={idx}>
                    <div className="hystory-date-time">
                        <p className="hystory-date">{sysDate(item.flow_date)}</p>
                        <span className="hystory-time">{sysTime(item.flow_date)}</span>
                    </div>
                    <p className="hystory-name">{item.flow_name}</p>
                </div>
        );

    // ----------------------------------------------------

    const handlePackageParts = () => {
        axiosDut.get(`packages/parts/${props.uniq_code}`)
            .then(res => {
                setPackageParts(res.data.payload);
                setOpenDialog(true);
            })
            .catch(error => {
                console.log("error axios");
            });
    };

    // ----------------------------------------------------

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ----------------------------------------------------

    return (
        <div className="history history-new">
            <div className="history-head">
                <div className="history-title">
                    <h3>История доставки заказа</h3>
                </div>
            </div>

            <RenderPartsButton handlePackageParts={handlePackageParts} flag_parts={props.flag_parts} />

            <div className="history-content">
                {historyItems}
            </div>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                className="dialog delivery-dialog parts"
            >
                <DialogTitle disableTypography className="dialog-title">
                    <Typography variant="h6">Состав заказа</Typography>
                    <IconButton aria-label="close" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="dialog-info">
                    <div className="order-content">
                        <RenderPackageParts parts={packageParts} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export function RenderTrackBlock(props) {

    let class_track = "trek-code trek-code-new";

    if (props.data.flag_delivery)
        class_track += " delivery";
    else
        class_track += " normal";

    // flag_stage_delivery = 2 Активно (белая иконка)
    // flag_stage_delivery = 1 Пройдено (серая иконка)
    // flag_stage_delivery = 0 В будущем (серая иконка)

    return (
        <div className={class_track}>

            <div className="trek-code-head">
                <h3>{props.data.delivery_label}</h3>
                <span>
                    {props.data.code}
                </span>
            </div>

            <hr className="trek-code-line-new" />

            <div className="trek-code-icon">

                <div className={props.data.flag_stage_prepare === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_prepare === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_prepare === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_prepare === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-1" + (props.data.flag_stage_prepare === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>Готовится к отгрузке</p>
                </div>

                <div className={props.data.flag_stage_ship === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_ship === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_ship === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_ship === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-2" + (props.data.flag_stage_ship === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>Заказ отправлен</p>
                </div>
                <div className={props.data.flag_stage_arrive === 2 ? "icon-active icon-default-center" : ("icon-default icon-default-center" + (props.data.flag_stage_arrive === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_arrive === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_arrive === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-3" + (props.data.flag_stage_arrive === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>Прибытие в распред. центр</p>
                </div>

                <div className={props.data.flag_stage_dispatch === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_dispatch === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_dispatch === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_dispatch === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-4" + (props.data.flag_stage_dispatch === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>{props.data.dispatch_label}</p>
                </div>

                <div className={props.data.flag_stage_delivery === 2 ? "icon-active" : ("icon-default" + (props.data.flag_stage_delivery === 1 ? " icon-grey" : ""))}>
                    <div className={props.data.flag_stage_delivery === 2 ? "icon-active-img" : ("icon-default-img" + (props.data.flag_stage_delivery === 1 ? " passed" : ""))}>
                        <img src={"/img/icon-5" + (props.data.flag_stage_delivery === 2 ? "-w" : "") + ".png"} alt="" />
                    </div>
                    <p>{props.data.final_label}</p>
                </div>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------