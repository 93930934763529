import React, { useEffect, lazy, Suspense } from 'react';
import DutSearchField from './SearchField';
import { sysLog } from '../js/dut_sys';

const RenderCodesBlock = lazy(() => import('./FirstCodeBlock'));
const RenderCodesBlockLoader = () => <div className="loader"></div>;

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function BodyFirst(props) {

    useEffect(() =>
    {
        document.title = `Трекинг интернет заказов B2CPL.ru`;
        sysLog("BodyFirst.Enter", "");
    }, []);


    return (
            <div className="content content-vh">
                <div className="content-top content-top-new">
                    <h1>Где заказ?</h1>
                    <p className="h1-subtitle">сервис для тех, кто хочет отследить доставку своего заказа</p>

                    <div className="search" id="block-search">
                        <DutSearchField />
                    </div>

                    <Suspense fallback={RenderCodesBlockLoader()}>
                        <RenderCodesBlock />
                    </Suspense>
                </div>
            </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------


