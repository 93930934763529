import React from 'react';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function DutAppBarIn(props) {

    return (
        <div className="header">
            <a className="back-link" href="/">
                <span className="material-icons">arrow_back</span>
            </a>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------


