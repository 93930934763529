import React, { useState, useEffect } from 'react';
import { RenderDateTimeBlock, RenderStatusBlock, RenderTrackBlock } from "../js/Renders"
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axiosDut from "../js/api_axios"
import { sysLog, sysDeliveryDate, sysDate, sysTime } from '../js/dut_sys';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

const bgStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function PinDialog(props) {
    const history = useHistory();
    const fullScreen = false;
    const { onClose, open } = props;

    const [stateAlert, setAlertState] = React.useState(false);
    const [stateAlertText, setAlertText] = React.useState("Сообщение");
    const [stateAlertType, setAlertType] = React.useState("success");
    const [stateDateSend, setDateSend] = React.useState(new Date(2001, 1, 1));

    // ----------------------------------------------------------------

    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertState(false);

        return;
    };

    // ----------------------------------------------------------------

    const showAlert = (text, type) => {
        setAlertType(type);
        setAlertText(text);
        setAlertState(true);
    };

    // ----------------------------------------------------------------

    const handleClose = (e, result) => {
        sysLog("BodyBrief.PinDialogClose", props.data.code);

        onClose(e, result);
    };

    // ----------------------------------------------------------------

    const handleSend = () => {
        const dateNow = new Date();

        if (((dateNow.getTime() - stateDateSend.getTime()) / 1000) > 30)
        {
            sysLog("BodyBrief.PinDialogSend.Ok", props.data.code);

            setDateSend(dateNow);

            axiosDut.get(`packages/pin/${props.data.code}`)
                .then(res => {

                    if(res.data.payload.sent)
                        showAlert(`PIN код отправлен на номер ${props.data.phone}`, "success");
                    else
                        showAlert(`Что-то сломалось. PIN код не отправлен`, "error");
                })
                .catch(error => {
                    console.log("error axios");
                    showAlert(`Что-то пошло не так`, "error");
                });
        }
        else
        {
            sysLog("BodyBrief.PinDialogSend.Error", props.data.code);
            showAlert(`Подождите 30 сек`, "error");
        }
    };

    // ----------------------------------------------------------------


    const handlePin = () => {
        let pin = "";
        const inp = document.getElementById("pin-code")

        if (inp)
        {
            pin = inp.value;
        }

        if (pin) {
            axiosDut.get(`packages/pin/${props.data.code}/${pin}`)
                .then(res => {
                    const url = "/t/" + res.data.payload.uniq_code;

                    if (res.data.payload.flag_verify && res.data.payload.uniq_code) {
                        sysLog("BodyBrief.PinDialogCheck.Found", { code: props.data.code, pin: pin });
                        history.push(url);
                    }
                    else
                    {
                        sysLog("BodyBrief.PinDialogCheck.Wrong", { code: props.data.code, pin: pin });
                        showAlert(`Неправильный PIN код`, "error");
                    }
                })
                .catch(error => {
                    sysLog("BodyBrief.PinDialogCheck.Error", { code: props.data.code, pin: pin });
                    console.log("error axios");
                    showAlert(`Что-то пошло не так`, "error");
                });
        }
        else
        {
            sysLog("BodyBrief.PinDialogCheck.Empty", { code:props.data.code, pin:pin });
            showAlert("Неправильный PIN код", "error");
        }
    };

    // ----------------------------------------------------------------

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className="dialog delivery-dialog pin"
        >
            <DialogTitle disableTypography className="dialog-title">
                <Typography variant="h6">Ваш PIN код</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className="dialog-info pin">
                <div className="in">
                    Введите PIN код для управления доставкой
                    <div className="pin-code">
                        <div className="inp">
                            <TextField id="pin-code" label="PIN код" maxLength="4" size="small" variant="outlined" />
                        </div>
                        <div>
                            <Button className="btn" color="primary" onClick={handlePin}>Ввести PIN</Button>
                        </div>
                    </div>
                </div>
                <div className="send">
                    Если у вас нет PIN кода или он не работает, мы можем отправить его вам по СМС на телефон <b>{props.data.phone}</b>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { handleSend() }} color="secondary" autoFocus>
                    Получить PIN код 
                </Button>
            </DialogActions>

            <Snackbar open={stateAlert} autoHideDuration={3000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={stateAlertType}>
                    {stateAlertText}
                </Alert>
            </Snackbar>

        </Dialog>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

function RenderAskBlock(props)
{
    const [stateDlg, setDlgState] = React.useState(false);

    // ----------------------------------------------------------------

    const handleDlgClose = (e, result) => {
        setDlgState(false);
    };

    // ----------------------------------------------------------------

    const openDialog = () => {
        setDlgState(true);
    };

    // ----------------------------------------------------------------

    const handlePin = (e, result) => {
        sysLog("BodyBrief.PinDialogOpen", props.data.code);
        openDialog();
    };

    // ----------------------------------------------------------------

    // Если у посылки финальный статус - не выводим диалог с ПИН кодом
    if (props.data.flag_stage_delivery !== 0)
    {
        return null;
    }

    // Нужно вывести вопрос про добавление посылки в список
    return (
        <div className="block-ask">
            <div className="ask-info">
                Для просмотра подробностей и управления доставкой зайдите по секретной ссылке, которую мы отправляли вам по email и СМС.
                <div className="p">Если у вас нет ссылки, получите PIN код для управления доставкой</div>
            </div>
            <div className="ask-button">
                <Button color="primary" variant="outlined" onClick={handlePin}>Получить PIN код</Button>
            </div>

            <PinDialog
                open={stateDlg}
                onClose={handleDlgClose}
                {...props}
            />
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function BodyBrief(props)
{
    const bgClasses = bgStyles();
    const [data, setData] = useState({});
    const [bgOpen, setBGOpen] = React.useState(true);

    useEffect(() =>
    {
        axiosDut.get(`packages/details/${props.match.params.code}`)
            .then(res => {
                setBGOpen(false);
                setData(res.data);
            })
            .catch(error => {
                sysLog("BodyBrief.Error", props.match.params.code);
                console.log("error axios");

                setBGOpen(false);
                setData({ flag_error: true, message: "Ошибка при получении данных" });
            });

    }, [props.match.params.code]);

    // не нашли или какая-то ошибка
    if (data && data.flag_error)
    {
        sysLog("BodyBrief.404", props.match.params.code);

        return (
            <div className="content uniq error">
                <div id="block-body">
                    <Icon>error_outline</Icon>
                    <div className="error-title">Что-то пошло не так. {data.message}</div>
                </div>
            </div>
        );
    }

    // Нашли - все ок
    if (data && !data.flag_error && data.payload)
    {
        sysLog("BodyBrief.Found", props.match.params.code);

        document.title = `${data.payload.code} - просмотр посылки`;

        return (
            <div className="content-delivery content-delivery-new brief">

                <div className="head">
                    <h2>{data.payload.state_name_tracking}</h2>
                </div>
                <RenderDateTimeBlock data={data.payload} flag_change={false} />
                <RenderAskBlock data={data.payload} />
                <RenderTrackBlock data={data.payload} />
                <RenderStatusBlock history={data.payload.history} flag_parts={false} />

            </div>
        );
    }

    // Пока нет данных
    return (
        <div className="content uniq empty error">
            <div id="block-body">
                <div className="error-title">Получаем данные...</div>
                <Backdrop className={bgClasses.backdrop} open={bgOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
}

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------


