import React from 'react';
import { useHistory } from 'react-router-dom';
import { sysLog, sysStorageGetUniq } from '../js/dut_sys';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axiosDut from "../js/api_axios"
import { Icon } from '@material-ui/core';
import { Fragment } from 'react';

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------

export default function DutSearchField()
{
    const [stateAlert, setAlertState] = React.useState(false);
    const [stateAlertText, setAlertText] = React.useState("Что-то пошло не так");
    const [stateInput, setInputState] = React.useState("");
    const history = useHistory();

    // ----------------------------------------------------------------

    const handleInputChange = (e) => {
        setInputState(e.target.value);
    };

    // ----------------------------------------------------------------

    const handleSearchClick = (e) =>
    {
        if (e)
            e.preventDefault();

        const code = stateInput;

        if (code.trim().length < 2)
        {
            sysLog("SearchField.Empty", code);
            setAlertText(`Введите трек номер`);
            setAlertState(true);
            return false;
        }

        const uniq_code = sysStorageGetUniq(code);

        if (uniq_code) {
            // Нашли код среди сохраненных
            sysLog("SearchField.Uniq", code);
            history.push(`/t/${uniq_code}`);
        }
        else
        {
            axiosDut.get(`packages/brief/${code}`)
                .then(res => {
                    if (res.data.flag_error) {
                        // Не нашли или ошибка
                        sysLog("SearchField.404", code);
                        setAlertText(res.data.message ? res.data.message : "Что-то не получилось");
                        setAlertState(true);
                    }
                    else {
                        // Нашли
                        sysLog("SearchField.Brief", code);
                        history.push(`/b/${res.data.payload.code}`);
                    }
                })
                .catch(error => {
                    sysLog("SearchField.Error", code);
                    console.log("error axios");
                    setAlertText(`Что-то пошло не так`);
                    setAlertState(true);
                });
        }

        return false;
    };

    // ----------------------------------------------------------------

    const handleAlertClose = (e, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setAlertState(false);

        return;
    };

    // ----------------------------------------------------------------

    const handleInputFocus = (event) => event.target.select();

    // ----------------------------------------------------------------

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    // ----------------------------------------------------------------

    return (
        <Fragment>

            <input type="text" onChange={handleInputChange} onFocus={handleInputFocus} onKeyPress={handleInputKeyPress} placeholder="Введите трек номер заказа" />
            <a href="/" onClick={handleSearchClick} className="transition">
                <img src="/img/search.png" alt="" />
            </a>

            <Snackbar open={stateAlert} autoHideDuration={3000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {stateAlertText}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}

