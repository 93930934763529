import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import BodyFirst from './BodyFirst'
import BodyUniq from './BodyUniq';
import BodyBrief from './BodyBrief';
import Body404 from './Body404';

export class AppBody extends Component {
    static displayName = AppBody.name;

  render () {
      return (
          <Switch>
              <Route exact path='/' render={(props) => (<BodyFirst {...props} />)} />
              <Route path='/t/:uniq/:delivery?' render={(props) => (<BodyUniq {...props} />)} />
              <Route path='/b/:code' render={(props) => (<BodyBrief {...props} />)} />
              <Route render={(props) => (<Body404 {...props} />)} />
          </Switch>
      );
  }
}
